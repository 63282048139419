import styled from "@emotion/styled"
import { FieldAbstraction } from "./FieldAbstractions"

const SelectInput = styled(`select`)`
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.grey[30]};
  border-radius: ${p => p.theme.radii[2]};
  color: ${p => p.theme.colors.grey[90]};
  font-size: ${p => p.theme.fontSizes[2]};
  height: 2.25rem;
  padding: 0 ${p => p.theme.space[4]};
  width: 100%;

  :focus {
    border-color: ${p => p.theme.colors.purple[40]};
    box-shadow: 0 0 0 3px ${p => p.theme.colors.purple[20]};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }
`

export const SelectInputBlock = FieldAbstraction({ Component: SelectInput })
