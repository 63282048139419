// logic pulled from this SO answer: https://stackoverflow.com/a/34064434/7531377
// takes string escaped html from a server and renders it like the DOM would
// to return a string like:
//    "<p>Hello world!</p>"
// instead of:
//    "&lt;p&gt;Hello world!&lt;/p&gt;"
// that string can be rendered by dangerouslySetInnerHTML
export function htmlDecode(input) {
  // don't decode during SSR
  if (typeof window !== `undefined`) {
    const doc = new DOMParser().parseFromString(input, "text/html")
    return doc.documentElement.textContent
  }

  return ``
}
