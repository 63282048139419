import React, { useState } from "react"
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik"
import { isEmpty, find } from "lodash"
import { Button } from "gatsby-interface"
import { TextInput, TextInputBlock } from "./form/TextInput"
import { SelectInputBlock } from "./form/Select"
import { Radio } from "./form/Radio"
import { TextAreaBlock } from "./form/TextArea"
import { FieldGroupAbstraction } from "./form/FieldAbstractions"
import delay from "../utils/delay"

/*
  Greenhouse's API returns certain fields in a specific shape requiring
  some work arounds that are commented throughout this file
*/

const GreenhouseForm = ({ jobId, questions }) => {
  const [file, setFile] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [response, setResponse] = useState(null)

  if (!questions) return null
  // Greenhouse returns multiple possible fields for certain questions
  // pass the questions through a function that filters down to the
  // single type of field we want
  questions = filterFieldTypes(questions)

  const initialValues = questions.reduce((merged, question) => {
    merged[question.field.name] = ``
    return merged
  }, {})

  const genValidationSchema = Yup =>
    questions.reduce((merged, q) => {
      if (q.required && q.field.name === `email`) {
        merged[q.field.name] = Yup.string()
          .email(`This field must be a valid email.`)
          .required(`This field is required.`)
      } else if (q.required && q.field.type !== `input_file`) {
        merged[q.field.name] = Yup.string().required(`This field is required.`)
      } else {
        merged[q.field.name] = Yup.string()
      }
      return merged
    }, {})

  return (
    <React.Fragment>
      <h3>Apply for this position</h3>
      <Formik
        initialValues={initialValues}
        validate={values => {
          return import(/* webpackChunkName: "yup"*/ "yup")
            .then(yup =>
              validateYupSchema(
                values,
                yup.object().shape(genValidationSchema(yup))
              )
            )
            .then(() => [])
            .catch(err => {
              // Yup will throw a validation error if validation fails. We catch those and
              // resolve them into Formik errors. We can sniff if something is a Yup error
              // by checking error.name.
              // @see https://github.com/jquense/yup#validationerrorerrors-string--arraystring-value-any-path-string
              if (err.name === "ValidationError") {
                return yupToFormErrors(err)
              }
              throw err
            })
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          // hit a serverless function to proxy the POST to Greenhouse
          const res = await fetch(
            `https://pp1cfxe2g6.execute-api.us-east-1.amazonaws.com/prod/postApplication`,
            {
              method: `POST`,
              body: JSON.stringify({
                jobId,
                ...values,
                // split base64 info so it gets sent with only the base64 content
                resume_content: file.base64.split(`,`)[1],
                resume_content_filename: file.name,
              }),
            }
          )
          setSubmitting(false)
          setSubmitted(true)
          const resJson = await res.json()
          setResponse(resJson)

          await delay(5000)

          resetForm({ values: initialValues })
          setFile(``)
        }}
      >
        {({ errors, isSubmitting, submitForm, setFieldValue }) => (
          <Form>
            {questions.map((question, index) => (
              <QuestionField
                key={index}
                question={question}
                setFieldValue={setFieldValue}
                setFile={setFile}
                file={file}
              />
            ))}
            <Button
              variant={`PRIMARY`}
              type="submit"
              onClick={submitForm}
              loading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors) || !file || submitted}
            >
              Submit Application
            </Button>
            <p
              css={theme => ({
                visibility: submitted ? `visible` : `hidden`,
                fontSize: theme.fontSizes[2],
                color:
                  response && response.error
                    ? theme.colors.red[60]
                    : theme.colors.blackFade[50],
                marginTop: theme.space[4],
              })}
            >
              {response && response.error
                ? `There seems to have been an error processing your application :( please try again, if you're still experiencing issues reach out to us at jobs@gatsbyjs.com`
                : `Thanks, we've received your application! You should receive an email soon confirming in addition to this message.`}
            </p>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default GreenhouseForm

const RadioInputGroupBlock = FieldGroupAbstraction({
  Component: Radio,
  hideLabel: false,
})

// fields that can come from Greenhouse, mapped to components
const QuestionField = ({ question, setFieldValue, setFile, file }) => {
  const isRequired = question.required

  const convertFileToBase64 = file =>
    new Promise(resolve => {
      const reader = new FileReader()
      const f = file

      reader.onloadend = () => {
        f.base64 = reader.result
        if (f.size >= 3200000) {
          // the 2 minute fix for making sure candidates don't send too large of a file to Lambda
          // in place of rigging up something to send resumes to s3 and then to Greenhouse
          window.alert(
            `Resume file size is too large. Please upload a file smaller than 3MB. If compressing your resume into a smaller size is not possible, contact us at jobs@gatsbyjs.com.`
          )
        } else {
          setFile(f)
        }
        return resolve(f.base64)
      }

      // Calls reader.onloadend when finished reading
      reader.readAsDataURL(f)
    })

  const getBase64 = event => {
    const file = event.target.files[0]
    return convertFileToBase64(file)
  }

  // decide the type of input to output based on the data from Greenhouse
  if (question.field.type === `multi_value_single_select`) {
    return (
      <SelectInputBlock
        label={question.label}
        fieldName={question.field.name}
        required={isRequired}
      >
        <option value="" />
        {question.field.values.map(({ value, label }, index) => (
          <option key={index} value={value.toString()}>
            {label}
          </option>
        ))}
      </SelectInputBlock>
    )
  } else if (question.field.type === `radio_custom`) {
    const options = question.field.values.map(({ value, label }) => {
      // stringify the values so that strict equality matches work
      value = value.toString()
      if (label === `Other`) {
        // override the Other field with a custom input box
        return {
          label: (
            <TextInput
              placeholder={`Custom`}
              onFocus={() => {
                setFieldValue(question.field.name, value)
              }}
              onBlur={() => {
                setFieldValue(question.field.name, value)
              }}
              css={{
                marginTop: -8,
                width: `100%`,
              }}
            />
          ),
          value,
        }
      } else {
        return { label, value: value.toString() }
      }
    })

    return (
      <React.Fragment>
        {question.description && (
          <div dangerouslySetInnerHTML={{ __html: question.description }} />
        )}
        <RadioInputGroupBlock
          label={question.label}
          fieldName={question.field.name}
          options={options}
          css={{
            "& > div": {
              display: `grid`,
              gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
              "& > div > label": {
                width: `100%`,
              },
            },
          }}
        />
      </React.Fragment>
    )
  } else if (question.field.type === `radio_select`) {
    const options = question.field.values.map(v => {
      return { ...v, value: v.value.toString() }
    })
    return (
      <RadioInputGroupBlock
        label={question.label}
        fieldName={question.field.name}
        options={options}
        css={{
          "& > div": {
            display: `grid`,
            gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
          },
        }}
      />
    )
  } else if (question.field.type === `textarea`) {
    return (
      <TextAreaBlock
        label={question.label}
        fieldName={question.field.name}
        type={question.field.type}
        required={isRequired}
        css={{ textarea: { height: `auto` } }}
        rows={5}
      />
    )
  } else if (question.field.type === `input_text`) {
    return (
      <TextInputBlock
        label={question.label}
        fieldName={question.field.name}
        type={question.field.type}
        required={isRequired}
      />
    )
  } else if (question.field.type === `input_file`) {
    // resume upload
    return (
      <div css={{ position: `relative` }}>
        <span css={{ position: `absolute`, top: 32, left: 120 }}>
          {file ? file.name : `No file selected`}
        </span>
        <TextInputBlock
          label={question.label}
          fieldName={question.field.name}
          type="file"
          required={isRequired}
          onChange={getBase64}
          title=" "
          css={theme => ({
            "[type=file]": {
              padding: theme.space[2],
              color: `#fff`,
            },
            input: {
              height: `100%`,
            },
          })}
        />
      </div>
    )
  } else {
    console.warn(
      `Question of type ${question.field.type} not found in serializers`
    )
    return null
  }
}

// Some questions from Greenhouse return multiple possible fields
// they need to be mapped to the field type we want to use on our end
const fieldMapping = new Map([
  // [label, fieldType]
  [`Resume`, `input_file`],
  [`Resume/CV`, `input_file`],
  [`Cover Letter`, `textarea`],
  // these field types are custom ones for our own use cases
  [`Which gender do you identify with?`, `radio_custom`],
  [`What is your race/ethnicity?`, `radio_custom`],
  [`Do you identify as LGBTQ+?`, `radio_select`],
])

const cherryPickField = question => {
  const fallbackField = question.fields[0]
  const fieldType = fieldMapping.get(question.label)

  // overwrite Greehouse field typese as custom field types
  if (fieldType === `radio_custom` || fieldType === `radio_select`) {
    return {
      name: fallbackField.name,
      values: fallbackField.values,
      type: fieldType,
    }
  }

  return (
    find(question.fields, field => field.type === fieldType) || fallbackField
  )
}

const filterFieldTypes = questions => {
  return questions.map(q => {
    const field = cherryPickField(q)
    return { ...q, field }
  })
}
