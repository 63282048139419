import React from "react"
import styled from "@emotion/styled"

const labelCss = theme => ({
  display: "flex",
  flexDirection: "column",

  small: {
    marginTop: theme.space[3],
  },

  [theme.mediaQueries.tablet]: {
    flexDirection: "row",

    small: {
      paddingLeft: theme.space[3],
      marginTop: "unset",
      alignSelf: "center",
    },
  },
})

const Label = styled.label`
  cursor: pointer;
  position: relative;

  :after,
  :before {
    border-radius: 50%;
    content: "";
    display: flex;
    left: 0;
    position: absolute;
    transition: 0.15s ease-in-out;
    top: 0;
  }

  :before {
    background: ${p => p.theme.colors.primaryBackground};
    border: 2px solid ${p => p.theme.colors.grey[30]};
    height: 22px;
    width: 22px;
  }

  :after {
    background: ${p => p.theme.colors.purple[60]};
    height: 8px;
    left: 7px;
    opacity: 0;
    top: 7px;
    width: 8px;
  }

  small {
    color: ${p => p.theme.colors.grey[50]};
    font-size: ${p => p.theme.fontSizes[1]};
  }
`

const RadioInput = styled.input`
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;

  &:checked + label::before {
    border-color: ${p => p.theme.colors.purple[60]};
  }

  &:checked + label::after {
    opacity: 1;
  }

  &:hover + label::before {
    border-color: ${p => p.theme.colors.purple[40]};
  }

  &:focus + label::before {
    border-color: ${p => p.theme.colors.purple[60]};
    box-shadow: 0 0 0 3px ${p => p.theme.colors.purple[20]};
  }
`

const StandardContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: ${p => p.theme.space[5]} ${p => p.theme.space[2]};
  position: relative;

  ${Label} {
    padding-left: ${p => p.theme.space[8]};

    :before {
      left: 0;
    }

    :after {
      left: 7px;
    }
  }
`

const ColourfulContainer = styled(StandardContainer)`
  margin: ${p => p.theme.space[2]} 0;
  padding: ${p => p.theme.space[5]};

  :before,
  :after {
    content: "";
    position: absolute;
    z-index: -1;
    background: #eee;
  }
  :before {
    border-radius: ${p => p.theme.radii[3]};
    bottom: 0;
    left: 0;
    opacity: 0;
    right: 0;
    top: 0;
  }
  :after {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background: ${p => p.theme.colors.white};
    border-radius: 6px;
  }

  :hover:not(.selected) {
    :before,
    :after {
      opacity: 1;
      background: ${p => p.theme.colors.purple[5]};
    }
  }

  &.selected {
    /* Setting z-index to 1 allows overflowing content to be displayed over sibling radios */
    z-index: 1;
    :before {
      opacity: 1;
      background-image: linear-gradient(
        110deg,
        #8954a8 0,
        #663399 25%,
        #bc027f 50%,
        #ffdf37 75%,
        #05f7f4 100%
      );
    }
  }

  ${Label} {
    width: 100%;
    padding-left: ${p => p.theme.space[8]};

    :before {
      top: 4px;
    }

    :after {
      left: 7px;
      top: 11px;
    }

    @media (min-width: ${p => p.theme.mediaBreakpoints.tablet}px) {
      padding-left: ${p => p.theme.space[10]};

      :before {
        left: ${p => p.theme.space[5]};
        /* transform: translateY(-50%); */
      }

      :after {
        left: calc(${p => p.theme.space[5]} + 7px);
        /* transform: translateY(-50%); */
      }
    }
  }
`
const containers = {
  standard: StandardContainer,
  colourful: ColourfulContainer,
}

export function Radio({
  type = `standard`,
  label,
  htmlLabel,
  fieldName,
  id,
  optionValue,
  value,
  className,
  children,
  ...rest
}) {
  const Container = containers[type]
  return (
    <Container
      className={`${optionValue === value ? `selected` : ``} ${className}`}
    >
      <RadioInput
        type="radio"
        name={fieldName}
        id={id}
        value={optionValue}
        checked={optionValue === value}
        {...rest}
      />
      {label && (
        <Label htmlFor={id} css={labelCss}>
          {label}
        </Label>
      )}
      {htmlLabel && (
        <Label htmlFor={id} css={labelCss}>
          {htmlLabel}
        </Label>
      )}
      {children}
    </Container>
  )
}
