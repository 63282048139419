import React, { Fragment } from "react"
import { Field, ErrorMessage } from "formik"
import styled from "@emotion/styled"
import { MdInfoOutline } from "react-icons/md"
import { MdErrorOutline } from "react-icons/md"
import { InputError } from "./OriginalForms"

const FieldBlock = styled(`div`)`
  margin-bottom: ${p => p.theme.space[4]};

  div:last-of-type {
    margin-bottom: 0;
  }
`

const FieldLabel = styled("label")`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${p => p.theme.colors.grey[50]};
  font-size: ${p => p.theme.fontSizes[1]};
  margin: ${p => p.theme.space[2]};
  margin-top: 0;

  span {
    color: ${p => p.theme.colors.purple[50]};
    font-size: ${p => p.theme.fontSizes[0]};
  }
`

const FieldTip = styled(`p`)`
  color: ${p => p.theme.colors.grey[50]};
  font-size: ${p => p.theme.fontSizes[0]};
  margin: ${p => p.theme.space[2]} ${p => p.theme.space[2]} 0
    ${p => p.theme.space[2]};

  svg {
    vertical-align: text-top;
  }
`

export const FieldError = styled(InputError)``

export function FieldAbstraction({
  FieldComponent = Field,
  Component,
  hideLabel = false,
}) {
  return function FieldAbstractionComponent({
    fieldName,
    label,
    fieldTip,
    placeholder,
    required,
    className,
    focusOnMount,
    ...rest
  }) {
    const fieldRef = React.useRef(null)

    React.useEffect(() => {
      if (focusOnMount && fieldRef.current) {
        fieldRef.current.focus()
      }
    }, [])

    return (
      <FieldBlock className={className ? className : ``}>
        <FieldComponent name={fieldName} aria-label={label}>
          {({ field, form, ...formikProps }) => (
            <Fragment>
              {!hideLabel && (
                <FieldLabel htmlFor={fieldName}>
                  {label} {required ? <span>required</span> : ``}
                </FieldLabel>
              )}
              <Component
                fieldName={fieldName}
                label={label}
                placeholder={placeholder}
                ref={fieldRef}
                {...field}
                {...rest}
                {...formikProps}
                {...form}
              />
              {fieldTip && (
                <FieldTip>
                  <MdInfoOutline /> {fieldTip}
                </FieldTip>
              )}
            </Fragment>
          )}
        </FieldComponent>
        <ErrorMessage name={fieldName}>
          {msg => (
            <FieldError
              data-cy={`lead-capture-${fieldName}-error-message`}
              data-testid="error-label"
            >
              <MdErrorOutline /> {msg}
            </FieldError>
          )}
        </ErrorMessage>
      </FieldBlock>
    )
  }
}

export function FieldGroupAbstraction({ Component, hideLabel = false }) {
  return ({
    options = [],
    fieldName,
    onFieldSet,
    label,
    className,
    ...rest
  }) => (
    <FieldBlock className={className}>
      {!hideLabel && <FieldLabel>{label}</FieldLabel>}
      <div>
        {options.map(({ label, value, ...optionRest }, index) => (
          <Field name={fieldName} aria-label={label} key={index}>
            {({ field, form, ...formikProps }) => (
              <Component
                data-cy={`field-` + value}
                fieldName={fieldName}
                optionValue={value}
                label={label}
                id={`${fieldName}${index}`}
                {...field}
                onChange={e => {
                  field.onChange(e)

                  if (onFieldSet) {
                    onFieldSet(e)
                  }
                }}
                {...rest}
                {...formikProps}
                {...form}
                {...optionRest}
              />
            )}
          </Field>
        ))}
      </div>
      <ErrorMessage name={fieldName}>
        {msg => (
          <InputError data-cy={`lead-capture-${fieldName}-error-message`}>
            <MdErrorOutline /> {msg}
          </InputError>
        )}
      </ErrorMessage>
    </FieldBlock>
  )
}
